import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { profileState, subscriptionSignUpState } from '../../recoil/atoms'
import pricingStyles from './pricing.module.scss'
import {
  SUBSCRIPTIONS,
  SUBSCRIPTION_TYPES,
  SEMRUSH_SUBSCRIPTIONS,
  ROOT_ROUTE,
} from '../../utils/consts'
import PricingCard from '../PricingCard/PricingCard'
import useModalState from '../../hooks/useModalState'
import SubChangeModal from '../SubChangeModal/SubChangeModal'
import PaymentResultModal from '../PaymentResultModal/PaymentResultModal'
import SubCancelModal from '../SubCancelModal/SubCancelModal'
import { getCurrentSubName } from '../../utils/subNameHelper'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'
import {
  calculateSubscriptionChangeFee,
  cancelSubscription,
  getInvoiceData,
  getNextPayment,
  getPriceData,
  trySubscriptionChange,
} from '../../services/stripeCallsHelper'
import { Profile } from '../../interfaces/profile'
import { changeConvertKitTag } from '../../services/convertKitHelper'
import Link from 'next/link'
import { getUser, updateSubscriptionData } from '../../services/profileHelper'
import { SubscriptionData, SubscriptionType as SubType } from '@prisma/client'
import { useSession } from 'next-auth/react'
import PaymentModal from '../PaymentModal/PaymentModal'
import classNames from 'classnames'
import { semrushUrl } from '@components/Header'

type PricingProps = {
  id?: string
  isLandingPage?: boolean
  semrushLanding?: boolean
  isSettingsPage?: boolean
}

type CancelTextProps = {
  type: SubType
  annual: boolean
}

export type SemrushTypes = 'SEMRUSH_BASIC' | 'SEMRUSH_PREMIUM'

function Pricing({
  id = 'pricing',
  isLandingPage = false,
  semrushLanding = false,
  isSettingsPage = false,
}: PricingProps) {
  const [profile, setProfile] = useRecoilState(profileState)
  const [activePlan, setActivePlan] = useState<SubType | null>(
    profile?.subscriptionData?.activePlan || null
  )

  //Save type change temporary until payment success
  const [priceType, setPriceType] = useState<SubType>()
  const [cancelText, setCancelText] = useState<CancelTextProps>({
    type: SUBSCRIPTION_TYPES.FREE.id,
    annual: false,
  })
  const [nextInvoiceDate, setnextInvoiceDate] = useState('')

  const [nextInvoiceAmount, setnextInvoiceAmount] = useState(0)
  const [chargeFee, setChargeFee] = useState(0)
  const router = useRouter()
  const [isAnnualPlan, setIsAnnuaPlan] = useState<boolean>(
    profile?.subscriptionData?.isAnnual || false
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false)
  const [prices, setPrices] = useState<any>({
    [SUBSCRIPTION_TYPES.STARTER.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.BASIC.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.PREMIUM.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.SEMRUSH_BASIC.id]: {
      annual: 66,
      monthly: 79,
    },
    [SUBSCRIPTION_TYPES.SEMRUSH_PREMIUM.id]: {
      annual: 107,
      monthly: 129,
    },
  })

  const [isPricingVisible, setIsPricingVisible] = useState(false)
  const [isModalOpen, handleOpen, handleClose] = useModalState(false)
  const [isSuccesModalOpen, handleSuccessOpen, handleSuccessClose] =
    useModalState(false)
  const [isCancelModalOpen, handleCancelOpen, handleCancelClose] =
    useModalState(false)
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false)
  const handlePaymentModalOpen = () => {
    setPaymentModalOpen(true)
  }
  const handlePaymentModalClose = async () => {
    setPaymentModalOpen(false)
    if (subSignUpData.subscriptionId) {
      await cancelSubscription(subSignUpData.subscriptionId)
    }
    setIsLoading(false)
  }
  const [subSignUpData, setSubSignUpData] = useRecoilState(
    subscriptionSignUpState
  )
  const [gradeType, setGradeType] = useState('')
  const [prevPlan, setPrevPlan] = useState<SubType>(SubType.FREE)

  const { status } = useSession()
  const authenticated = status === 'authenticated'

  const routeTo = authenticated ? '/settings/subscription?nextPlan=' : '/signup'

  const pricingRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPricingVisible(true)
            if (pricingRef.current) observer.unobserve(pricingRef.current)
          }
        })
      },
      { threshold: 0.2 }
    )

    if (pricingRef.current) {
      observer.observe(pricingRef.current)
    }

    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (semrushLanding) return
    const getPrice = async () => {
      try {
        const priceData = await getPriceData()
        setPrices(priceData)
        if (profile?.subscriptionData?.subscriptionId) {
          setnextInvoiceAmount(
            profile.subscriptionData.isAnnual
              ? priceData[profile.subscriptionData.nextPlan].annual
              : priceData[profile.subscriptionData.nextPlan].monthly
          )

          const next_date = new Date(
            parseInt(profile.subscriptionData.nextBillingDate) * 1000
          )
            .toISOString()
            .slice(0, 10)

          setnextInvoiceDate(' ' + next_date + ' ')
        }
      } catch (err) {
        console.error(err)
      }
    }
    // Prevent state update on unmounted component if the process is cancellation
    const isFree = router.query.nextPlan === SUBSCRIPTION_TYPES.FREE.id
    if (!isFree) {
      getPrice()
    }
  }, [profile])

  // Get the current profile and update the connected plan info, ONLY IF there is no profile yet
  useEffect(() => {
    const getActualProfile = async () => {
      try {
        if (authenticated && !profile) {
          const actualProfile = await getUser()
          if (actualProfile && actualProfile.subscriptionData) {
            setProfile(actualProfile)
            setIsAnnuaPlan(actualProfile.subscriptionData?.isAnnual)
            setActivePlan(actualProfile.subscriptionData?.activePlan)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
    getActualProfile()
  }, [authenticated])

  // Update the next plan info according to the choosen plan, if the user got navigated from the landing page
  useEffect(() => {
    const getParamsFromUrl = async () => {
      const planFromRoute = router.query.nextPlan
      const isAnnual = router.query.isAnnual
      if (
        planFromRoute &&
        isAnnual !== undefined &&
        profile?.subscriptionData
      ) {
        setIsAnnuaPlan(isAnnual === 'true')
        await fetchSubscriptionChange(
          planFromRoute as SubType,
          isAnnual === 'true'
        )
      }
    }
    getParamsFromUrl()
  }, [])

  const handleAmountDue = async (profile: Profile) => {
    if (!profile.subscriptionData || !profile.subscriptionData.subscriptionId) {
      return
    }
    const { subscriptionId, isAnnual, nextPlan } = profile.subscriptionData
    const { amount_due } = await getInvoiceData(
      subscriptionId,
      nextPlan,
      isAnnual
    )
    setnextInvoiceAmount(amount_due / 100)
    setChargeFee(amount_due / 100)
  }

  async function checkAnnual(changedValue: boolean) {
    setIsAnnuaPlan(changedValue)
  }

  const handleSemrushPricingCardButtonClick = (
    subscriptionPlan: SemrushTypes
  ) => {
    router.push(semrushUrl)
    return
  }

  const handlePricingCardButtonClick = async (subscriptionPlan: SubType) => {
    if (profile && profile.subscriptionData) {
      setPrevPlan(profile.subscriptionData.activePlan)
    }
    if (isLandingPage) {
      const subPrices = isAnnualPlan
        ? prices[subscriptionPlan].annual_id
        : prices[subscriptionPlan].monthly_id

      setSubSignUpData({
        priceId: subPrices,
        customerStripeId: '',
        type: subscriptionPlan,
        annual: isAnnualPlan,
        subscriptionId: '',
      })
      if (authenticated) {
        router.push(
          routeTo +
            subscriptionPlan +
            `&isAnnual=${isAnnualPlan}&priceId=${subPrices}`
        )
      } else {
        if (subscriptionPlan === SUBSCRIPTION_TYPES.FREE.id) {
          router.push(routeTo)
        } else {
          router.push(routeTo)
        }
      }
      return
    }
    if (isSettingsPage && profile?.subscriptionData?.subscriptionId) {
      await fetchSubscriptionChange(subscriptionPlan)
      return
    }
    createCheckoutSession(subscriptionPlan)
  }

  const fetchSubscriptionChange = async (
    subscriptionPlan: SubType,
    isAnnual: boolean = isAnnualPlan
  ) => {
    setPriceType(subscriptionPlan)
    setIsLoading(true)
    try {
      if (profile) {
        if (
          profile.subscriptionData?.activePlan !== SUBSCRIPTION_TYPES.FREE.id &&
          subscriptionPlan === SUBSCRIPTION_TYPES.FREE.id
        ) {
          await router.push('/settings/cancellation')
          return
        } else if (!profile.subscriptionData?.subscriptionId) {
          createCheckoutSession(subscriptionPlan)
          return
        }
        await handleChargeFee(subscriptionPlan, isAnnual)
      }
      setIsLoading(false)
      handleOpen()
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleChargeFee = async (
    newSubscriptionPlan: SubType,
    isAnnual: boolean = isAnnualPlan
  ) => {
    if (profile && profile.subscriptionData?.subscriptionId) {
      const invoice = await getInvoiceData(
        profile.subscriptionData.subscriptionId,
        newSubscriptionPlan,
        isAnnual
      )
      const fee = calculateSubscriptionChangeFee(invoice)
      setChargeFee(fee / 100)
    }
  }

  const updateSubscription = async () => {
    if (!profile || !profile.subscriptionData?.subscriptionId || !priceType) {
      return
    }
    setIsPaymentSuccessful(false)
    setIsLoading(true)

    const grade = getGradeType(prevPlan)
    setGradeType(grade === 'Upgrade' ? 'upgraded' : 'downgraded')
    try {
      handleClose()
      setIsPaymentSuccessful(true)
      const isChangeSuccessful = await trySubscriptionChange(
        profile.subscriptionData.subscriptionId,
        priceType,
        isAnnualPlan
      )
      if (!isChangeSuccessful) {
        handleSuccessOpen()
        setIsLoading(false)
        return
      }
      setActivePlan(priceType)
      setCancelText({
        type: profile.subscriptionData.activePlan || '',
        annual: false,
      })
      await changeConvertKitTag(
        profile.email,
        profile.subscriptionData.activePlan,
        priceType
      )
      const isCancel =
        priceType === SUBSCRIPTION_TYPES.FREE.id &&
        profile.subscriptionData.activePlan !== SUBSCRIPTION_TYPES.FREE.id
      const nextInvoiceDate = await getNextPayment(
        profile.subscriptionData.subscriptionId,
        priceType,
        isAnnualPlan
      )
      const subscriptionUpdateQuery: Partial<SubscriptionData> = isCancel
        ? {
            isAnnual: isAnnualPlan,
            nextPlan: priceType,
          }
        : {
            activePlan: priceType,
            isAnnual: isAnnualPlan,
            nextPlan: priceType,
            nextBillingDate: String(nextInvoiceDate),
          }

      const updatedSubscriptionData = await updateSubscriptionData(
        subscriptionUpdateQuery
      )
      handleAmountDue(profile)

      if (updatedSubscriptionData) {
        const newProfile: Profile = {
          ...profile,
          subscriptionData: updatedSubscriptionData as any,
        }
        setProfile(newProfile)
        priceType === SUBSCRIPTION_TYPES.FREE.id
          ? handleCancelOpen()
          : handleSuccessOpen()
      }

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      handleSuccessOpen()
      setIsLoading(false)
    }
  }

  const createCheckoutSession = async (subscriptionPlan: SubType) => {
    if (!profile || !profile.subscriptionData) {
      return
    }

    let subPriceId = isAnnualPlan
      ? prices[subscriptionPlan].annual_id
      : prices[subscriptionPlan].monthly_id
    if (!subPriceId) {
      subPriceId = String(router.query.priceId)
    }

    setSubSignUpData({
      priceId: subPriceId,
      customerStripeId: profile.subscriptionData.stripeCustomerID,
      type: subscriptionPlan,
      annual: isAnnualPlan,
      subscriptionId: '',
    })

    handlePaymentModalOpen()
  }

  const getGradeType = (activePlan: SubType) => {
    if (
      (activePlan === SUBSCRIPTION_TYPES.PREMIUM.id &&
        priceType === SUBSCRIPTION_TYPES.BASIC.id) ||
      (activePlan === SUBSCRIPTION_TYPES.BASIC.id &&
        priceType === SUBSCRIPTION_TYPES.STARTER.id) ||
      (activePlan === SUBSCRIPTION_TYPES.BASIC.id &&
        priceType === SUBSCRIPTION_TYPES.STARTER.id)
    ) {
      return 'Downgrade'
    } else {
      return 'Upgrade'
    }
  }

  const handleSuccesModalClick = () => {
    handleSuccessClose
    router.push(ROOT_ROUTE)
  }

  return (
    <section
      id={id}
      className={
        !isSettingsPage ? pricingStyles.pricing : pricingStyles.setting_pricing
      }
    >
      {isLoading && <LoadingOverlay />}
      {profile && priceType && (
        <SubChangeModal
          profile={profile}
          isModalOpen={isModalOpen}
          isAnnualPlan={isAnnualPlan}
          priceType={priceType}
          chargeFee={chargeFee}
          handleClose={handleClose}
          upgradeSubscription={updateSubscription}
          gradeType={getGradeType}
          computeChargeFee={() => handleChargeFee(priceType)}
        />
      )}
      <PaymentResultModal
        isModalOpen={isSuccesModalOpen}
        isPaymentSuccessful={isPaymentSuccessful}
        handleClose={handleSuccessClose}
        handleClick={handleSuccesModalClick}
        gradeType={gradeType}
        nextPlan={profile?.subscriptionData?.nextPlan as string}
      />
      <SubCancelModal
        isModalOpen={isCancelModalOpen}
        nextInvoiceDate={nextInvoiceDate}
        cancelText={cancelText}
        handleClose={handleCancelClose}
      />
      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        handleClose={handlePaymentModalClose}
      />
      <h2 className={`${pricingStyles.title} ${pricingStyles.blackText}`}>
        {isLandingPage ? 'Pricing' : 'Select pricing'}
      </h2>

      <div className={pricingStyles.annualContainer}>
        <div className={pricingStyles.annualToggleContainer}>
          <div
            className={
              isAnnualPlan
                ? `${pricingStyles.annualTogglebackground} ${pricingStyles.right}`
                : pricingStyles.annualTogglebackground
            }
          />
          {/*todo: bug Your current subscription is Unkown*/}

          <button
            className={pricingStyles.toggle}
            onClick={() => checkAnnual(false)}
            aria-label="Monthly"
          >
            <span
              className={
                !isAnnualPlan
                  ? `${pricingStyles.toggleText} ${pricingStyles.white}`
                  : pricingStyles.toggleText
              }
            >
              monthly
            </span>
          </button>
          <button
            className={pricingStyles.toggle}
            onClick={() => checkAnnual(true)}
            aria-label="Anually"
          >
            <span
              className={
                isAnnualPlan
                  ? `${pricingStyles.toggleText} ${pricingStyles.white}`
                  : pricingStyles.toggleText
              }
            >
              annually
            </span>
          </button>
        </div>

        <span
          className={classNames(
            pricingStyles.saveMore,
            pricingStyles.blackText
          )}
        >
          Save 20% annually
        </span>

        {(isSettingsPage || (!isSettingsPage && authenticated)) &&
          profile?.subscriptionData?.activePlan !==
            SUBSCRIPTION_TYPES.FREE.id && (
            <p
              className={classNames(
                pricingStyles.subtext,
                pricingStyles.blackText
              )}
            >
              Your current subscription is
              {` ${getCurrentSubName(profile?.subscriptionData?.activePlan)}`}.
              Next billing date is
              {nextInvoiceDate === '' ? ' not known ' : nextInvoiceDate}
              and we will charge ${nextInvoiceAmount} from your card. After that
              your subscription will be
              {profile?.subscriptionData?.isAnnual ? ' annual ' : ' '}
              {getCurrentSubName(profile?.subscriptionData?.nextPlan)}.
            </p>
          )}
      </div>

      <div
        className={classNames(
          isPricingVisible
            ? `${pricingStyles.cards} ${pricingStyles.onScreen}`
            : `${pricingStyles.cards}`,
          {
            [pricingStyles.semrush]: semrushLanding,
          }
        )}
        ref={pricingRef}
      >
        {semrushLanding
          ? SEMRUSH_SUBSCRIPTIONS.map((sub) => (
              <PricingCard
                key={sub.id}
                subDetails={sub}
                handleClick={handlePricingCardButtonClick}
                handleSemrushClick={handleSemrushPricingCardButtonClick}
                isAnnual={isAnnualPlan}
                isActive={sub.id === activePlan}
                isSettingsPage={isSettingsPage}
                prices={prices[sub.id]}
                isButtonDisabled={
                  profile?.subscriptionData?.activePlan === SubType.GOD ||
                  (sub.id === SUBSCRIPTION_TYPES.FREE.id &&
                    profile?.subscriptionData?.activePlan !==
                      SUBSCRIPTION_TYPES.FREE.id &&
                    profile?.subscriptionData?.nextPlan ===
                      SUBSCRIPTION_TYPES.FREE.id)
                }
              />
            ))
          : SUBSCRIPTIONS.map((sub) => (
              <PricingCard
                key={sub.id}
                subDetails={sub}
                handleClick={handlePricingCardButtonClick}
                handleSemrushClick={handleSemrushPricingCardButtonClick}
                isAnnual={isAnnualPlan}
                isActive={sub.id === activePlan}
                isSettingsPage={isSettingsPage}
                prices={prices[sub.id]}
                isButtonDisabled={
                  profile?.subscriptionData?.activePlan === SubType.GOD ||
                  (sub.id === SUBSCRIPTION_TYPES.FREE.id &&
                    profile?.subscriptionData?.activePlan !==
                      SUBSCRIPTION_TYPES.FREE.id &&
                    profile?.subscriptionData?.nextPlan ===
                      SUBSCRIPTION_TYPES.FREE.id)
                }
              />
            ))}
      </div>
      {isSettingsPage &&
        profile?.subscriptionData?.activePlan !== SUBSCRIPTION_TYPES.FREE.id &&
        profile?.subscriptionData?.nextPlan !== SUBSCRIPTION_TYPES.FREE.id && (
          <div className={pricingStyles.cancelBtn}>
            <Link href="/settings/cancellation">
              <a>Cancel subscription</a>
            </Link>
          </div>
        )}
    </section>
  )
}

export default Pricing
